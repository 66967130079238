<template>
    <div class="tw-grid-col-4">
        <div class="mb-3">
            <label class="col-label form-label required tw-text-xs">{{
                $t('fixedAllowance.employeeId')
            }}</label>
            <div class="col-md-12">
                <Select
                    v-model="model.employee_id"
                    filterable
                    remote
                    :remote-method="searchEmployee"
                    :loading="loading"
                    class="from-label"
                    :class="{
                        'is-invalid': errors.has('employee_id')
                    }"
                >
                    <Option
                        v-for="(emp, index) in employeeProfile"
                        :value="emp.employee_id"
                        :key="index"
                    >
                        {{
                            emp.card_id +
                            '-' +
                            emp.employee_name_kh +
                            '-' +
                            emp.employee_name_en
                        }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('employee_id')">
                    {{ errors.first('employee_id') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col-md-6">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('fixedAllowance.allowanceId')
                }}</label>
                <Select
                    v-model="model.allowance_id"
                    filterable
                    remote
                    :remote-method="searchAllowance"
                    :loading="loading"
                    class="from-label"
                    :class="{
                        'is-invalid': errors.has('allowance_id')
                    }"
                >
                    <Option
                        v-for="(allow, index) in itemAllowance"
                        :value="allow.allowance_id"
                        :key="index"
                    >
                        {{ allow.allowance_item }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('allowance_id')">
                    {{ errors.first('allowance_id') }}
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('fixedAllowance.allowance')
                }}</label>
                <input
                    v-model="model.allowance"
                    type="number"
                    class="form-control from-label"
                    :class="{
                        'is-invalid': errors.has('allowance')
                    }"
                />
                <div class="invalid-feedback" v-if="errors.has('allowance')">
                    {{ errors.first('allowance') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col-md-6">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('fixedAllowance.effectiveDate')
                }}</label>
                <DatePicker
                    :value="model.effective_date"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Select date"
                    style="width: 233px"
                    class="from-label"
                    @on-change="date => (model.effective_date = date)"
                    :class="{
                        'is-invalid': errors.has('effective_date')
                    }"
                ></DatePicker>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('effective_date')"
                >
                    {{ errors.first('effective_date') }}
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label class="col-label form-label tw-text-xs">{{
                    $t('fixedAllowance.expiryDate')
                }}</label>
                <DatePicker
                    :value="model.expiry_date"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Select date"
                    style="width: 233px"
                    class="from-label"
                    @on-change="date => (model.expiry_date = date)"
                ></DatePicker>
            </div>
        </div>
        <div class="mb-3">
            <label class="col-label form-label tw-text-xs">{{
                $t('fixedAllowance.description')
            }}</label>
            <div class="col-md-12">
                <textarea
                    v-model="model.description"
                    class="form-control from-label"
                >
                </textarea>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t('saveAddNew') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}
                </ts-button>
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onUpdate"
                >
                    {{ $t('update') }}
                </ts-button>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState, mapActions } from 'vuex'
import { debounce, isEmpty } from 'lodash'
export default {
    name: 'fixed-allowance-form',
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            model: {
                employee_id: null,
                allowance_id: null,
                allowance: null,
                effective_date: null,
                expiry_date: null,
                description: null
            },
            itemAllowance: [],
            employeeProfile: []
        }
    },
    computed: {
        ...mapState('payroll/fixedAllowance', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        allowances () {
            return this.itemAllowance.map(el => ({
                value: el.allowance_id,
                label: el.allowance_item
            }))
        }
    },
    created () {
        this.fetchResource({
            fnName: 'itemAllowance,employeeProfile'
        })
    },
    methods: {
        ...mapActions('payroll/fixedAllowance', ['formViewModel']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        searchAllowance: debounce(async function (query) {
            var avoidSearch = this.itemAllowance.find(
                allow => allow.allowance_item == query.trim()
            )
            if (avoidSearch) return
            this.fetchResource({
                fnName: 'itemAllowance',
                allow_search: query
            })
        }, 500),

        searchEmployee: debounce(async function (query) {
            var avoidSearch = this.employeeProfile.find(
                emp =>
                    emp.card_id +
                        '-' +
                        emp.employee_name_kh +
                        '-' +
                        emp.employee_name_en ==
                    query.trim()
            )
            if (avoidSearch) return
            this.fetchResource({
                fnName: 'employeeProfile',
                emp_search: query
            })
        }, 500),
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/fixedAllowance/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch(
                    'payroll/fixedAllowance/store',
                    Object.assign({}, this.model)
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warnig',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        setEditData () {
            this.model.employee_id = this.edit_data.employee_id
            this.model.allowance_id = this.edit_data.allowance_id
            this.model.allowance = this.edit_data.allowance
            this.model.effective_date = this.edit_data.effective_date
            this.model.expiry_date = this.edit_data.expiry_date
            this.model.description = this.edit_data.description
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/fixedAllowance/update', {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.allowance_id = null
            this.model.allowance = null
            this.model.effective_date = null
            this.model.expiry_date = null
            this.model.description = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'FIXED ALLOWANCE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
