<template>
    <div>
        <ts-page-title :title="$t('fixedAllowance.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <Button
                        type="success"
                        @click.prevent="onShowExport"
                        :loading="exporting"
                    >
                        <i class="fas fa-file-excel"></i> {{ $t('export') }}
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        icon="ios-cloud-upload"
                        @click="importIncentive"
                    >
                        {{ $t('import') }}
                    </Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('fixedAllowance.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                size="small"
                max-height="700"
                :loading="loading"
                :data="resources"
                stripe
            >
                <template slot-scope="{ row }" slot="employee">
                    {{ row.card_id }}
                </template>
                <template slot-scope="{ row }" slot="employee">
                    {{ $root.$i18n.locale === 'kh' ? row.employee_name_kh : row.employee_name_en }}
                </template>
                <template slot-scope="{ row }" slot="allowanceName">
                    {{ row.allowance_item }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                        placement="left"
                        :transfer="true"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <!-- v-if="showForm"  -->
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('fixedAllowance.fixedAllowance')"
        >
            <FormComponent
                ref="form_action"
                @cancel="clearEdit"
                @fetchData="fetchData"
            />
        </Modal>
        <Modal
            v-model="show_export_form"
            :centered="true"
            :closable="false"
            :zIndex="1020"
            :title="$t('export')"
            :okText="$t('yes')"
            :cancelText="$t('no')"
            @on-ok="onExport"
            @on-cancel="clearEdit"
            :confirmLoading="exporting"
            width="450px"
        >
            <ts-loading-banner
                :loading="export_resource_loading"
                class="ts-space-y-2"
            >
                <div class="mb-3">
                    <label class="form-label col-label">{{
                        $t('branchName')
                    }}</label>
                    <Select
                        v-model="model.branch_id"
                        multiple
                        :max-tag-count="1"
                        :filterable="true"
                        :filter-by-label="true"
                    >
                        <Option
                            v-for="item in branches"
                            :value="item.branch_id"
                            :key="item.branch_id"
                            :label="item.branch_name_en"
                        >
                            {{ item.branch_name_en }}
                        </Option>
                    </Select>
                </div>
                <div class="mb-3">
                    <RadioGroup v-model="model.id_type">
                        <Radio
                            class="mt-1"
                            :label="c.value"
                            border
                            v-for="(c, index) in calculateTypes"
                            :key="index"
                        >
                            {{ c.label }}
                        </Radio>
                    </RadioGroup>
                </div>
                <div class="mb-3" v-if="model.id_type == 1">
                    <div class="col-md-12">
                        <label>{{
                            $t('fixedAllowance.orgChartStructure')
                        }}</label>
                        <Select
                            v-model="model.org_chart_structure_id"
                            filterable
                            :loading="loading"
                            class="from-label"
                            :placeholder="$t('all')"
                            :class="{
                                'is-invalid': errors.has(
                                    'org_chart_structure_id'
                                )
                            }"
                            :clearable="true"
                            @on-change="onOrgChange"
                        >
                            <Option
                                v-for="(org, index) in orgStructure"
                                :value="org.org_chart_structure_id"
                                :key="index"
                            >
                                {{ org.org_chart_structure }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('org_chart_structure_id')"
                        >
                            {{ errors.first('org_chart_structure_id') }}
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="model.id_type == 2">
                    <div class="col-md-12">
                        <label>{{ $t('fixedAllowance.shiftName') }}</label>
                        <Select
                            v-model="model.shift_id"
                            filterable
                            :loading="loading"
                            class="from-label"
                            multiple
                            :max-tag-count="2"
                            :placeholder="$t('all')"
                            @on-change="onShiftChange"
                        >
                            <Option
                                v-for="(s, index) in shift"
                                :value="s.shift_id"
                                :key="index"
                            >
                                {{ s.shift_name + '-' + s.shift_type }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('shift_id')"
                        >
                            {{ errors.first('shift_id') }}
                        </div>
                    </div>
                </div>
                <div class="row tw-mb-6">
                    <div class="col-sm-12">
                        <label class="form-label col-label">{{
                            $t('fixedAllowance.employee')
                        }}</label>
                        <Select
                            v-model="model.employee_id"
                            filterable
                            remote
                            :remote-method="searchEmployee"
                            :loading="loading"
                            class="from-label"
                            multiple
                            :max-tag-count="2"
                            :placeholder="$t('all')"
                        >
                            <Option
                                v-for="(emp, index) in employeeProfile"
                                :value="emp.employee_id"
                                :key="index"
                            >
                                {{
                                    emp.card_id +
                                    '-' +
                                    emp.employee_name_kh +
                                    '-' +
                                    emp.employee_name_en
                                }}
                            </Option>
                        </Select>
                    </div>
                </div>
            </ts-loading-banner>
        </Modal>
        <Modal
            v-model="showFormImport"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('fixedAllowance.importFixedAllowance')"
        >
            <ImportFixedAllowance
                ref="import_allowance"
                @cancel="() => (showFormImport = false)"
                @fetchData="fetchData"
            />
        </Modal>
    </div>
</template>

<script>
import FormComponent from './form'
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
import { trim } from 'lodash'
import * as FileDownload from 'downloadjs'
import ImportFixedAllowance from './import-fixed-allowance'

export default {
    name: 'fixed-allowance',
    components: {
        FormComponent,
        ImportFixedAllowance
    },
    data () {
        return {
            errors: new Errors(),
            loading: false,
            width: 'width: 300px',
            showForm: false,
            show_export_form: false,
            exporting: false,
            export_resource_loading: false,
            showFormImport: false,
            calculateTypes: [
                {
                    value: 1,
                    label: 'Organizational Structure'
                },
                {
                    value: 2,
                    label: 'Shift'
                }
            ],
            branches: [],
            orgStructure: [],
            employeeProfile: [],
            model: {
                branch_id: [],
                id_type: 1,
                shift_id: [],
                employee_id: [],
                org_chart_structure_id: null
            }
        }
    },
    computed: {
        ...mapState('payroll/fixedAllowance', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.payroll.fixedAllowance.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/fixedAllowance/SET_SEARCH',
                    newValue
                )
                this.$store.commit('payroll/fixedAllowance/RESET_CURRENT_PAGE')
            }
        },

        columns () {
            return [
                {
                    title: this.$t('fixedAllowance.cardId'),
                    key: 'card_id'
                },
                {
                    title: this.$t('fixedAllowance.employeeId'),
                    key: 'employee_name_en',
                    slot: 'employee'
                },
                {
                    title: this.$t('fixedAllowance.allowanceId'),
                    key: 'allowance_item',
                    slot: 'allowanceName'
                },
                {
                    title: this.$t('fixedAllowance.allowance'),
                    key: 'allowance'
                },
                {
                    title: this.$t('fixedAllowance.effectiveDate'),
                    key: 'effective_date'
                },
                {
                    title: this.$t('fixedAllowance.expiryDate'),
                    key: 'expiry_date'
                },
                {
                    title: this.$t('fixedAllowance.description'),
                    key: 'description'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('payroll/fixedAllowance', ['formViewModel']),

        addNew () {
            this.$refs.form_action.clearInput()
            this.showForm = true
            this.$refs.form_action.fetchResource({
                fnName: 'employeeProfile'
            })
        },
        onShowExport () {
            this.show_export_form = true
            this.fetchBranch()
            this.fetchExportResource({
                fnName: 'orgStructure,shift,employeeProfile'
            })
            this.clearInput()
        },
        searchEmployee: debounce(async function (query) {
            if (this.employeeProfile.length > 0) {
                var avoidSearch = this.employeeProfile.find(
                    emp =>
                        emp.card_id +
                            '-' +
                            emp.employee_name_kh +
                            '-' +
                            emp.employee_name_en ==
                        query.trim()
                )
                if (avoidSearch) return
            }
            this.fetchExportResource({
                fnName: 'employeeProfileSearch',
                emp_search: query,
                org_chart_structure_id: this.model.org_chart_structure_id,
                shift_id: this.model.shift_id,
                branch_id: this.model.branch_id
            })
        }, 500),
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/fixedAllowance/fetch', attributes)
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.branches = response.data
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async fetchExportResource (attributes) {
            this.export_resource_loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.export_resource_loading = false
        },
        async onOrgChange (value) {
            this.model.employee_id = []
            this.fetchExportResource({
                fnName: 'employeeProfile',
                org_chart_structure_id: value
            })
        },
        async onShiftChange (value) {
            this.model.employee_id = []
            this.fetchExportResource({
                fnName: 'employeeProfile',
                shift_id: value
            })
        },
        onEdit (row) {
            this.showForm = true
            this.$store.commit('payroll/fixedAllowance/SET_EDIT_DATA', row)
            this.$refs.form_action.setEditData()
        },
        onExport () {
            this.exporting = true
            this.errors = new Errors()
            this.$store
                .dispatch('payroll/fixedAllowance/exportExcel', this.model)
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                    this.show_export_form = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.exporting = false
                })
        },
        importIncentive () {
            this.showFormImport = true
            this.$refs.import_allowance.fetchResource()
            this.$refs.import_allowance.clearInput()

        },
        async onDelete (row) {
            row._deleting = true
            this.$store
                .dispatch('payroll/fixedAllowance/destroy', row.record_id)
                .then(response => {
                    this.fetchData()
                    row._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    row._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'FIXED ALLOWANCE',
                desc: not.text
            })
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit('payroll/fixedAllowance/SET_EDIT_DATA', {})
            this.$refs.form_action.clearInput()
        },
        clearInput () {
            this.errors = new Errors()
            this.model.branch_id = null
            this.model.org_chart_structure_id = null
            this.model.shift_id = null
            this.model.employee_id = null
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>

<style lang="scss" scoped></style>
